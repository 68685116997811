import { createAuthenticationAdapter } from '@rainbow-me/rainbowkit';
import { SiweMessage } from 'siwe';

import { generateNonce, verifySignedMessageForNetwork } from '@zealy/queries';
import { chainIdToNetwork } from '@zealy/utils';

import { toast } from '#components/Toaster';

// https://www.rainbowkit.com/docs/authentication
export const authenticationAdapter = (
  desiredChainId: number,
  onSuccess?: () => void | Promise<void>,
) =>
  createAuthenticationAdapter({
    getNonce: generateNonce,
    createMessage: ({
      nonce,
      address,
      chainId,
    }: {
      nonce: string;
      address: string;
      chainId: number;
    }) => {
      if (desiredChainId !== chainId) {
        throw new Error('Chain ID mismatch');
      }

      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement: 'Sign in to Zealy',
        uri: window.location.origin,
        version: '1',
        chainId: desiredChainId,
        nonce,
      });
      return message.prepareMessage();
    },
    verify: async ({ message, signature }: { message: string; signature: string }) => {
      try {
        const parsedMessage = new SiweMessage(message);
        await verifySignedMessageForNetwork(
          signature,
          parsedMessage,
          chainIdToNetwork(parsedMessage.chainId),
        );
        await onSuccess?.();
        return true;
      } catch (error: unknown) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
        console.error('[AuthenticationAdapter:Verify] failed', error);
        return false;
      }
    },
    signOut: async () => {},
  });
