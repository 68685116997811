import React from 'react';
import { ConnectButton as Base } from '@rainbow-me/rainbowkit';
import { useTranslations } from 'next-intl';
import { useSwitchChain } from 'wagmi';

import type { ButtonProps } from '@zealy/design-system';
import type { BlockchainNetwork } from '@zealy/utils';
import { Button } from '@zealy/design-system';
import { BLOCKCHAINS } from '@zealy/utils';

import { WalletProvider } from './Provider';

type WalletButtonWrapperProps = {
  ready: boolean;
  children: React.ReactNode;
};

const WalletButtonWrapper = ({ ready = false, children }: WalletButtonWrapperProps) => {
  if (!ready) {
    return (
      <div
        aria-hidden={true}
        style={{
          opacity: 0,
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {children}
      </div>
    );
  }

  return <div>{children}</div>;
};

type WalletButtonContentProps = {
  chain: any;
  openChainModal: () => void;
  openConnectModal: () => void;
  connected: boolean;
  buttonProps?: ButtonProps;
  desiredChainId: number;
};

const WalletButtonContent = ({
  chain,
  openChainModal,
  openConnectModal,
  connected,
  buttonProps,
  desiredChainId,
}: WalletButtonContentProps) => {
  const t = useTranslations('settings.linked-accounts');
  const { switchChain } = useSwitchChain();

  const onClick = () => {
    if (chain?.id !== desiredChainId) {
      switchChain({ chainId: desiredChainId });
    }
    openConnectModal();
  };

  if (chain?.unsupported) {
    return (
      <Button onClick={openChainModal} type="button" color="cta" variant="muted" {...buttonProps}>
        Wrong network
      </Button>
    );
  }

  return (
    <Button onClick={onClick} type="button" color="cta" variant="muted" {...buttonProps}>
      {buttonProps?.children ?? (connected ? t('update') : t('connect-wallet'))}
    </Button>
  );
};

export const ConnectButton = ({
  blockchain = 'eth-mainnet',
  buttonProps,
}: {
  blockchain?: BlockchainNetwork;
  buttonProps?: ButtonProps;
}) => {
  return (
    <WalletProvider blockchain={blockchain}>
      <Base.Custom>
        {({ account, chain, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
          const desiredChainId = BLOCKCHAINS[blockchain].chainId;
          const ready = Boolean(mounted && authenticationStatus !== 'loading');

          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === 'authenticated');

          return (
            <WalletButtonWrapper ready={ready}>
              <WalletButtonContent
                chain={chain}
                openChainModal={openChainModal}
                openConnectModal={openConnectModal}
                connected={!!connected}
                buttonProps={buttonProps}
                desiredChainId={desiredChainId}
              />
            </WalletButtonWrapper>
          );
        }}
      </Base.Custom>
    </WalletProvider>
  );
};
