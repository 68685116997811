import type { AccountType, Community, CommunityMember } from '@zealy/queries';
import type { GetQuestOutput } from '@zealy/utils';
import { convertBlockchainNameToNetwork } from '@zealy/utils';

import type { Identification } from '#constants/quests/Task.constants';
import { TASK_CONFIG } from '#constants/quests/Task.constants';

const nonBlockchainIdentifications = new Set([
  'discord',
  'email',
  'twitter',
  'tiktok',
  'wallet',
] as const);

const filterOutNonBlockchainIds = (ids: Set<Identification>) => {
  return Array.from(ids).filter(id => !nonBlockchainIdentifications.has(id as any));
};

const requiredFieldsList = [
  'fillEmail',
  'fillWallet',
  'linkWallet',
  'linkTwitter',
  'linkDiscord',
] as const;

const mapRequiredFieldToAccount = (
  requiredField: keyof Community['requiredFields'],
  communityBlockchain: string,
) => {
  switch (requiredField) {
    case 'linkDiscord':
      return 'discord';
    case 'linkTwitter':
      return 'twitter';
    case 'fillEmail':
      return 'email';
    case 'linkWallet':
      return 'eth-mainnet';
    case 'fillWallet':
      return communityBlockchain;
  }
};

export const getMissingAuth = ({
  tasks,
  rewards,
  user,
  requiredFields,
  communityBlockchain,
}: {
  tasks: GetQuestOutput['tasks'];
  rewards: GetQuestOutput['rewards'];
  user: Pick<CommunityMember, 'accounts' | 'addresses'>;
  requiredFields: Community['requiredFields'] | undefined;
  communityBlockchain: Community['blockchain'];
}) => {
  const validAccounts =
    user.accounts?.filter(
      ({ tokenStatus, accountType }) =>
        (accountType !== 'twitter' && accountType !== 'tiktok') || tokenStatus === 'valid',
    ) ?? [];

  const communityBlockchainNetwork =
    convertBlockchainNameToNetwork(communityBlockchain) ?? communityBlockchain;
  const hasCommunityBlockchainAddress =
    communityBlockchain === 'none' || !!user?.addresses?.[communityBlockchainNetwork];

  const isUserAccountConnected = (type: AccountType) =>
    validAccounts.some(({ accountType }) => accountType === type);

  const isAccountConnected = (
    type: 'discord' | 'email' | 'twitter' | 'tiktok' | 'wallet' | string,
  ): boolean => {
    switch (type) {
      case 'wallet':
        return !!user?.addresses?.['eth-mainnet'];
      case communityBlockchainNetwork:
        return hasCommunityBlockchainAddress;
      case 'discord':
        return isUserAccountConnected('discord');
      case 'twitter':
        return isUserAccountConnected('twitter');
      case 'email':
        return isUserAccountConnected('email');
      case 'tiktok':
        return isUserAccountConnected('tiktok');
      default:
        return false;
    }
  };

  const requiredFieldsIdentifications = requiredFieldsList
    .filter(type => {
      return (
        requiredFields?.[type] &&
        !isAccountConnected(mapRequiredFieldToAccount(type, communityBlockchainNetwork))
      );
    })
    .map(type => mapRequiredFieldToAccount(type, communityBlockchainNetwork));

  const requiredRewardsIdentifications = [
    ...rewards.reduce((acc, curr) => {
      if (curr.type !== 'token') return acc;
      acc.add(curr.settings.network);
      return acc;
    }, new Set<string>()),
  ];

  const identifications = new Set([
    ...requiredFieldsIdentifications,
    ...requiredRewardsIdentifications,
    ...tasks.flatMap(task => {
      const accounts =
        TASK_CONFIG[task.type as keyof typeof TASK_CONFIG]?.identifications?.(task.settings) ?? [];
      const hasAnyIdentification = accounts.some(id => isAccountConnected(id as AccountType));
      return hasAnyIdentification ? [] : accounts;
    }),
  ]);

  const missingBlockchainAuth = filterOutNonBlockchainIds(identifications).reduce(
    (acc, curr) =>
      acc[curr] || !!user.addresses?.[curr]
        ? acc
        : {
            ...acc,
            [curr]: !user.addresses?.[curr],
          },
    {} as Record<Identification, boolean>,
  );

  const requiredFieldsStatus = {
    'eth-mainnet': identifications.has('wallet'),
    ...missingBlockchainAuth,
    discord: identifications.has('discord'),
    tiktok: identifications.has('tiktok'),
    twitter: identifications.has('twitter'),
    email: identifications.has('email'),
  };

  return Object.entries(requiredFieldsStatus)
    .filter(([, required]) => required)
    .map(([type]) => type);
};

export const getQuestRelatedConnectedAddresses = ({
  tasks,
  rewards,
  user,
  communityBlockchain,
}: {
  tasks: GetQuestOutput['tasks'];
  rewards: GetQuestOutput['rewards'];
  user: Pick<CommunityMember, 'addresses'>;
  communityBlockchain: Community['blockchain'] | undefined;
}): { network: string; address: string }[] => {
  const addresses = user.addresses ?? {};
  const communityBlockchainNetwork =
    convertBlockchainNameToNetwork(communityBlockchain) ?? communityBlockchain;

  const rewardNetworks = new Set(
    rewards.filter(reward => reward.type === 'token').map(reward => reward.settings.network),
  );

  const taskNetworks = new Set(
    tasks
      .filter(task => !['nft', 'token'].includes(task.type))
      .flatMap(task => {
        const accounts =
          TASK_CONFIG[task.type as keyof typeof TASK_CONFIG]?.identifications?.(task.settings) ??
          [];
        return accounts.filter(id => !nonBlockchainIdentifications.has(id as any));
      }),
  );

  const relevantNetworks = new Set(
    [...rewardNetworks, ...taskNetworks].filter(n => n !== communityBlockchainNetwork),
  );

  return Object.entries(addresses)
    .filter(([network, address]) => !!address && relevantNetworks.has(network))
    .map(([network, address]) => ({
      network,
      address,
    }))
    .sort((a, b) =>
      a.network === communityBlockchainNetwork ? -1 : a.network.localeCompare(b.network),
    );
};
